import { watchEffect, onMounted, ref } from "vue";
import { Preferences } from "@capacitor/preferences";

export function useGlobalMessage() {
  const message = ref(null);
  const readMessageId = ref(null);
  const settings = useSettings();
  const globalMessage = settings.value?.global_message;

  // Load stored message ID on mount
  onMounted(async () => {
    const stored = await Preferences.get({ key: "globalMessageReadId" });
    readMessageId.value = stored.value || null;
  });

  watchEffect(() => {
    message.value =
        globalMessage?.id !== readMessageId.value ? globalMessage : null;
  });

  const markAsRead = async () => {
    if (message.value) {
      readMessageId.value = message.value.id;
      message.value = null; // Hide message in UI

      await Preferences.set({
        key: "globalMessageReadId",
        value: String(readMessageId.value),
      });
    }
  };

  return { message, markAsRead };
}
