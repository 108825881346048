<script setup>
import { onBeforeUnmount, onMounted } from "vue";
import { Keyboard } from "@capacitor/keyboard";

const { platform } = useRuntimeConfig().public;

useHead({
  title: "Canteen",
});

const router = useRouter();
const showMobileNavigation = computed(() => {
  return (
    // platform === "mobile" &&
    router.currentRoute.value.name.includes("profile")
  );
});

useHead({
  htmlAttrs: {
    class: platform === "mobile" ? "disable-scrollbars" : "",
  },
});

const appLayout = ref(null);
const preventSwipeBack = function (e) {
  if (e.touches && e.touches.length > 0) {
    const touch = e.touches[0];
    // If the touch event is not near the edge of the screen, exit
    if (touch.pageX > 20 && touch.pageX < window.innerWidth - 20) {
      return;
    }

    // Prevent the swipe to navigate gesture
    e.preventDefault();
  }
};

onMounted(() => {
  if (platform === "mobile") {
    Keyboard.addListener("keyboardDidShow", () => {
      document.activeElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    });
  }

  appLayout.value.addEventListener("touchstart", preventSwipeBack);
});

onBeforeUnmount(() => {
  if (platform === "mobile") {
    Keyboard.removeAllListeners();
  }

  appLayout.value.removeEventListener("touchstart", preventSwipeBack);
});
</script>

<template>
  <div
    id="app-layout"
    ref="appLayout"
    class="flex flex-col min-h-screen h-full w-screen font-sans caret-primary-600"
  >
    <AppGlobalMessage />
    <main class="min-h-screen h-full overflow-y-scroll pb-[76px]">
      <slot />

      <AppMobileNavigation v-if="showMobileNavigation" />
    </main>
  </div>
</template>

<style>
.page-left-enter-active,
.page-left-leave-active,
.page-right-enter-active,
.page-right-leave-active {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: all 200ms ease-in-out;
}

.page-left-enter-from,
.page-right-leave-to {
  transform: translateX(100%);
}

.page-left-leave-to,
.page-right-enter-from {
  transform: translateX(-100%);
}

.page-left-enter-to,
.page-right-enter-to {
  transform: translateX(0);
}

.disable-scrollbars *::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
  display: none;
}

.disable-scrollbars * {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overscroll-behavior-x: none; /* Chrome/Safari disable swipe back */
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
  display: none;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
