<script setup>
import { XCircleIcon, MegaphoneIcon } from "@heroicons/vue/24/outline";
const { message, markAsRead } = useGlobalMessage();
</script>

<template>
  <div  v-if="message?.id" class="fixed bottom-0 w-full mb-8-safe px-3-safe z-[9999]">
    <div
      class="w-full bg-blue-600 text-white p-3 rounded-md"
    >
      <div class="max-w-7xl mx-auto flex items-center justify-between">
        <div class="flex items-center gap-3">
          <MegaphoneIcon class="h-6 w-6 flex-shrink-0" />
          <p class="text-sm md:text-base flex-1" v-html="message.text"/>
        </div>
        <button
          class="p-2 rounded-md hover:bg-blue-500 focus:ring-2 focus:ring-white"
          @click="markAsRead"
        >
          <XCircleIcon class="h-5 w-5" />
        </button>
      </div>
    </div>
  </div>
</template>
